<template>
  <section class="transfer-types">
    <h2 class="transfer-types__heading">Type of Transfer</h2>
    <div class="transfer-types__wrapper">
      <div class="type" @click="$router.push('/dashboard/local-transfer')">
        <div class="type__svg">
          <img src="../../assets/images/local.svg" alt="Local Transfer" />
        </div>
        <p class="type__text">
          Local<br />
          Transfer
        </p>
      </div>
      <!-- <div class="type">
        <div class="type__svg">
          <img src="../../assets/images/foreign.svg" alt="Foreign Transfer" />
        </div>
        <p class="type__text">
          Foreign<br />
          Transfer
        </p>
      </div> -->
      <!-- <div class="type">
        <div class="type__svg">
          <img src="../../assets/images/ecommerce.svg" alt="Pay E-Commerce" />
        </div>
        <p class="type__text">Pay<br />E-commerce</p>
      </div> -->
      <!-- <div class="type">
        <div class="type__svg">
          <img src="../../assets/images/cheque.svg" alt="Cheque Deposit" />
        </div>
        <p class="type__text">Cheque<br />Deposit</p>
      </div> -->
      <!-- <div class="type" @click="$router.push('/dashboard/foreign-transfer')">
        <div class="type__svg">
          <img src="../../assets/images/foreign.svg" alt="Foreign Transfer" />
        </div>
        <p class="type__text">
          Foreign<br />
          Transfer
        </p>
      </div>
      <div class="type" @click="$router.push('/dashboard/pay-ecommerce')">
        <div class="type__svg">
          <img src="../../assets/images/ecommerce.svg" alt="Pay E-Commerce" />
        </div>
        <p class="type__text">Pay<br />E-commerce</p>
      </div> -->

      <div class="type" @click="$router.push('/dashboard/cheque-deposit')">
        <div class="type__svg">
          <img src="../../assets/images/cheque.svg" alt="Cheque Deposit" />
        </div>
        <p class="type__text">Cheque<br />Deposit</p>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.transfer-types {
  margin-inline: auto;
  width: 90%;
  max-width: 35rem;
  &__heading {
    font-family: Red Hat Display;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.4rem;
    letter-spacing: 0.05em;
    color: #000000;
    text-align: center;
    margin-bottom: 3rem;
  }
  &__wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 3rem;
  }
  .type {
    cursor: pointer;
    // cursor: not-allowed;
    // cursor: pointer;
    // &:nth-of-type(1) {
    //   cursor: pointer;
    // }
    // &:nth-of-type(4) {
    //   cursor: pointer;
    // }
    &__svg {
      background: #ffe4e5;
      padding: 4rem;
      border-radius: 1rem;
      margin-bottom: 2rem;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 80%;
      }
    }
    &__text {
      font-family: Red Hat Display;
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2.4rem;
      letter-spacing: 0.05em;
      color: #000000;
      text-align: center;
    }
  }
  // 480px
  @media only screen and (max-width: 30em) {
    .type {
      &__svg {
        padding: 3rem;
      }
    }
  }
}
</style>
